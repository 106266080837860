import React from 'react'
import { Link } from 'gatsby'
import * as styles from './Brand.module.scss'
import cn from 'classnames'

import AxicubeLogo from 'components/Icons/Axicube'
import AxicubeLightLogo from 'components/Icons/AxicubeLight'

const Brand = ({ variant, className }) => (
  <Link to="/" className={cn(styles.brand, className)}>
    {variant === 'dark' ? (
      <AxicubeLogo className={styles.brand__logo} />
    ) : (
      <AxicubeLightLogo className={styles.brand__logo} />
    )}
    <b
      className={cn(
        styles.brand__title,
        variant === 'dark' ? styles.dark : styles.light,
      )}>
      AXICUBE
    </b>
  </Link>
)

export default Brand
