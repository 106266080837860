import React from 'react'

import * as styles from './Adress.module.scss'

import adressSettings from 'site/settings/adress_settings.json'

const Adress = () => (
  <div className={styles.container}>
    <h5 className={styles.container__title}>{adressSettings.title}</h5>
    <p className={styles.container__par}>{adressSettings.first_line}</p>
    <p className={styles.container__par}>{adressSettings.second_line}</p>
  </div>
)

export default Adress
