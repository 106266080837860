import React, { useState } from 'react'

import * as styles from './NavigationLinksBurger.module.scss'

import BurgerButton from './BurgerButton'
import BurgerMenu from './BurgerMenu'

import { useDelayUnmount } from 'utils'

const NavigationLinksBurger = ({
  isOpenBurger,
  onToggleBurger,
  onCloseBurger,
  links,
  onButtonClick,
}) => {
  const shouldRenderChild = useDelayUnmount(isOpenBurger, 300)

  return (
    <>
      <BurgerButton onClick={onToggleBurger} isActive={isOpenBurger} />
      {shouldRenderChild && (
        <BurgerMenu
          links={links}
          onClickHireUs={onCloseBurger}
          onButtonClick={onButtonClick}
          className={isOpenBurger ? styles.fadeIn : styles.fadeOut}
        />
      )}
    </>
  )
}

export default NavigationLinksBurger
