import React from 'react'
import * as styles from './LogosTicker.module.scss'

import partner from 'site/partner/partner.json'
import partner_settings from 'site/partner/settings.json'
import { ImageFromPath } from 'utils'

const LogosTicker = () => (
  <div
    className={styles.tickerWrap}
    style={{ '--speed': `${partner_settings.ticker_speed || 24}s` }}>
    <div className={styles.ticker}>
      {partner?.Partner?.map(({ image, title }, i) => (
        <div key={`ticker1-${title}-${i}`} className={styles.ticker__headline}>
          <ImageFromPath url={image} alt={title} />
        </div>
      ))}
    </div>
    <div className={styles.ticker}>
      {partner?.Partner?.map(({ image, title }, i) => (
        <div key={`ticker2-${title}-${i}`} className={styles.ticker__headline}>
          <ImageFromPath url={image} alt={title} />
        </div>
      ))}
    </div>
  </div>
)

export default LogosTicker
