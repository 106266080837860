import React from 'react'
const Letter = props => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M40 10H8C6.89543 10 6 10.8954 6 12V36C6 37.1046 6.89543 38 8 38H40C41.1046 38 42 37.1046 42 36V12C42 10.8954 41.1046 10 40 10Z"
      stroke="#14181F"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 12L24.5143 26L42 12"
      stroke="#14181F"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Letter
