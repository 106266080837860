import React, { createContext, useContext, useEffect, useState } from 'react'
import { useDelayUnmount } from 'utils'

import FeedbackMesage from 'components/FeedbackMesage'

const FeedbackMesageContext = createContext()

const useFeedbackMesage = () => {
  const context = useContext(FeedbackMesageContext)

  if (typeof context === undefined) {
    throw new Error(
      'useFeedbackMesage must be used within a FeedbackMesageContext',
    )
  }

  return context
}

export const FeedbackMesageProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [feedbackMesage, setFeedbackMesage] = useState('')

  const shouldRender = useDelayUnmount(open, 500)

  const closeFeedbackMesage = () => setOpen(false)

  const openFeedbackMesage = () => setOpen(true)

  return (
    <FeedbackMesageContext.Provider
      value={{ closeFeedbackMesage, openFeedbackMesage, setFeedbackMesage }}>
      {children}
      {shouldRender && (
        <FeedbackMesage
          text={feedbackMesage}
          onButtonClick={closeFeedbackMesage}
          className={open ? 'feedbackMesageFadeIn' : 'feedbackMesageFadeOut'}
        />
      )}
    </FeedbackMesageContext.Provider>
  )
}

export const FeedbackMesageConsumer = FeedbackMesageContext.Consumer

export default useFeedbackMesage
