import React from 'react'
import * as styles from './DefaultButton.module.scss'

import { Link } from 'gatsby'

import cn from 'classnames'

const DefaultButton = ({
  title,
  type,
  link,
  radio,
  onClick,
  className,
  variant = '',
  disabled,
  ...props
}) => {
  // Variants: primary, secondary, ghost, light.
  const classNames = Array.isArray(variant)
    ? variant.map(v => styles[v])
    : [styles[variant]]

  if (type === 'radio') {
    return (
      <>
        <input type="radio" {...props} className={styles.radioInput} />
        <label
          htmlFor={props.id}
          className={cn(styles.button, ...classNames, className)}
          onClick={onClick ? onClick : null}>
          {title}
        </label>
      </>
    )
  }

  if (link) {
    return (
      <Link
        to={link}
        className={cn(styles.button, ...classNames, className)}
        {...props}>
        {title}
      </Link>
    )
  }

  const Element = props.as || 'button'

  return (
    <Element
      type={type}
      disabled={disabled}
      onClick={onClick ? onClick : null}
      className={cn(styles.button, ...classNames, className)}
      {...props}>
      {title}
    </Element>
  )
}

export default DefaultButton
