import React from 'react'
const Cross = props => {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M1 1L13 13" stroke="var(--color-black-dark)" strokeWidth="2" />
      <path d="M13 1L1 13" stroke="var(--color-black-dark)" strokeWidth="2" />
    </svg>
  )
}

export default Cross
