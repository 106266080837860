import React from 'react'

import Brand from 'components/Brand'
import Links from './Links'
import Images from './Images'
import Copywrite from './Copywrite'
import Menu from './Menu'
import Adress from './Adress'

const Footer = () => (
  <footer>
    <div className="container">
      <div className="row">
        <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12">
          <Brand variant="dark" />
          <Links />
          <Images />
          <Adress />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12">
          <Menu />
        </div>
      </div>
      <Copywrite />
    </div>
  </footer>
)

export default Footer
