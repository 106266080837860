import React, { useState } from 'react'
import cn from 'classnames'

import * as styles from './BurgerButton.module.scss'

const BurgerButton = ({ isActive, onClick }) => {
  return (
    <div className={styles.wrapper}>
      <button
        className={cn(styles.burger, { [styles.active]: isActive })}
        onClick={onClick}>
        <span className={cn(styles.burger__line, styles.one)}></span>
        <span className={cn(styles.burger__line, styles.two)}></span>
        <span className={cn(styles.burger__line, styles.three)}></span>
      </button>
    </div>
  )
}

export default BurgerButton
