import React, { useState } from 'react'
import * as styles from './Form.module.scss'

import cn from 'classnames'
import useFeedbackMesage from 'contexts/FeedbackMesageContext'
import axios from 'axios'

import * as validator from 'validator'

import { DefaultButton as Button, Input } from 'ui'

import contact_settings from 'site/settings/contact_settings.json'

const axiosStaticOptions = {
  url: 'https://api.emailjs.com/api/v1.0/email/send-form',
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
}

const defaultValidator = {
  name: { value: false, message: '' },
  email: { value: false, message: '' },
}

const defaultFormState = {
  name: null,
  email: null,
  description: null,
  files: null,
}

const Form = ({ isModal, afterSubmit }) => {
  const { setFeedbackMesage, openFeedbackMesage } = useFeedbackMesage()

  const [loading, setLoading] = useState(false)
  const [validatorState, setValidator] = useState(defaultValidator)

  const [formState, setformState] = useState(defaultFormState)

  const handleChangeForm = () => setValidator(defaultValidator)

  const setFormValue = (name, value) => {
    setformState(prev => ({ ...prev, [name]: value }))
  }

  const clear = name => {
    setValidator(c => ({ ...c, [name]: { value: false, message: '' } }))
  }

  const validate = () => {
    let isInValid = false
    if (!validator.isEmail(formState.email || '')) {
      setValidator(c => ({
        ...c,
        email: { value: true, message: 'Email is not valid' },
      }))
      isInValid = true
    } else {
      clear('email')
    }
    if (
      !validator.isByteLength(formState.name || '', { min: 3, max: undefined })
    ) {
      setValidator(c => ({
        ...c,
        name: { value: true, message: 'Min length 3' },
      }))
      isInValid = true
    } else {
      clear('name')
    }
    return !isInValid
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const isValid = validate()
    if (isValid) {
      setLoading(true)
      const formData = new FormData()
      Object.keys(formState).map(key => {
        formData.append(key, formState[key])
      })
      formState.files?.map((f, i) => {
        formData.append(`file-${1}`, f)
      })
      formData.append('form-name', 'hireus')
      formData.append('service_id', 'hello@axicube.io')
      formData.append('template_id', 'template_zm1524k')
      formData.append('user_id', 'user_VT1ITYE8nOGxGI4Z2aK6E')

      await axios({ ...axiosStaticOptions, data: formData })
        .then(() => {
          setformState(defaultFormState)
          setFeedbackMesage(contact_settings.feedback_message)

          openFeedbackMesage()
          setLoading(false)
          afterSubmit && afterSubmit()
        })
        .catch(err => console.log(err))
    }
  }

  return (
    <form
      className={styles.form}
      encType="multipart/form-data"
      name="hireus"
      method="POST"
      onSubmit={handleSubmit}
      onChange={handleChangeForm}
      noValidate>
      <div className={styles.form__fields}>
        <Input
          error={validatorState.name?.message}
          type="text"
          name="name"
          required
          placeholder="Your name"
          setFormValue={setFormValue}
        />
        <Input
          error={validatorState.email?.message}
          type="text"
          name="email"
          required
          placeholder="E-mail"
          setFormValue={setFormValue}
        />
        <Input
          error={validatorState.description?.message}
          isTextarea={true}
          inputClassName={styles.textarea}
          placeholder="Tell about your idea (optinal)"
          required
          name="description"
          setFormValue={setFormValue}
        />
      </div>

      <div
        className={cn(styles.form__btnWrap, {
          [styles.inModalBtn]: isModal,
        })}>
        <Button
          disabled={loading}
          type="submit"
          title="Send"
          className={styles.form__btnWrap__btn}
        />
      </div>
    </form>
  )
}

export default Form
