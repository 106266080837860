import React from 'react'
import { Link } from 'gatsby'
import * as styles from './Copywrite.module.scss'

const Copywrite = () => (
  <div className={styles.container}>
    <p>© Axicube {new Date().getFullYear()}</p>
    <ul className={styles.container__list}>
      <li>
        <Link to="/terms">
          <p>Terms of conditions</p>
        </Link>
      </li>
      <li>
        <Link to="/privacy-policy">
          <p>Privicy Policy</p>
        </Link>
      </li>
    </ul>
  </div>
)

export default Copywrite
