import React from 'react'
import cn from 'classnames'

import * as styles from './FeedbackMesage.module.scss'

import Portal from 'components/Portal'
import { DefaultButton as Button } from 'ui'
import Letter from 'components/Icons/Letter'

const FeedbackMesage = ({ text, onButtonClick, className }) => {
  return (
    <Portal>
      <div className={cn(styles.main, className)}>
        <div className={cn('container', styles.wrapper)}>
          <div className={styles.wrapper__container}>
            <div className={styles.wrapper__container__iconWrap}>
              <Letter className={styles.wrapper__container__iconWrap__icon} />
            </div>
            <p className={styles.wrapper__container__text}>{text}</p>
          </div>
          <div className={styles.wrapper__container}>
            <Button
              type="button"
              title="Got It!"
              className={styles.wrapper__container__button}
              onClick={onButtonClick}
            />
          </div>
        </div>
      </div>
    </Portal>
  )
}

export default FeedbackMesage
