import React from 'react'

import * as styles from './NavigationLinks.module.scss'

import { Link } from 'gatsby'
import { DefaultButton as Button } from 'ui'

const NavigationLinks = ({ links, onButtonClick }) => {
  return (
    <ul className={styles.list}>
      {links.map(({ title, linkTo }, i) => (
        <li
          className={styles.list__item}
          key={`NavigationLinks-link-${linkTo}-${i}`}>
          <Link to={linkTo} className={styles.list__item__link}>
            {title}
          </Link>
        </li>
      ))}
      <li className={styles.list__item}>
        <Button
          type="button"
          title="Let’s Talk"
          variant="secondary"
          onClick={onButtonClick}
          style={{ fontSize: '16px', lineHeight: '24px' }}
        />
      </li>
    </ul>
  )
}

export default NavigationLinks
