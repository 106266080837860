import React from 'react'
const Axicube = props => (
  <svg
    viewBox="0 0 32 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M15.9731 37.9998C15.6972 37.9971 15.4263 37.9244 15.1857 37.7884L0.78792 29.4101C0.543592 29.273 0.341106 29.0713 0.202324 28.8267C0.0635417 28.5822 -0.00626458 28.3041 0.000441457 28.0226L0.131688 9.94457C0.137602 9.66908 0.212975 9.39963 0.350726 9.16153C0.488477 8.92344 0.684083 8.7245 0.919167 8.58343L15.225 0.2184C15.4655 0.0754196 15.7397 0 16.0191 0C16.2984 0 16.5726 0.0754196 16.8131 0.2184L31.2108 8.58343C31.4497 8.72684 31.6477 8.92994 31.7856 9.17304C31.9236 9.41614 31.9969 9.69101 31.9983 9.971L31.8539 28.049C31.8545 28.3258 31.7818 28.5978 31.6435 28.837C31.5051 29.0762 31.3059 29.274 31.0665 29.4101L16.7606 37.7884C16.52 37.9244 16.2491 37.9971 15.9731 37.9998ZM3.15036 27.1239L15.9731 34.5771L28.7172 27.1239L28.8353 10.8696L16.0256 3.41641L3.2816 10.8828L3.15036 27.1239Z"
      fill="white"
    />
    <path
      d="M16.1186 19.9218C15.8426 19.9192 15.5718 19.8464 15.3311 19.7104L0.920291 11.3321C0.680719 11.1929 0.481804 10.9925 0.343564 10.7513C0.205324 10.51 0.132635 10.2363 0.132813 9.95778C0.135244 9.67978 0.209033 9.40713 0.347015 9.16632C0.484997 8.9255 0.682482 8.72471 0.920291 8.58343L15.2261 0.2184C15.4667 0.0754196 15.7409 0 16.0202 0C16.2995 0 16.5737 0.0754196 16.8142 0.2184L31.212 8.58343C31.4519 8.72248 31.6511 8.92273 31.7897 9.16401C31.9282 9.40529 32.0012 9.67907 32.0012 9.95778C32.0012 10.2365 31.9282 10.5103 31.7897 10.7516C31.6511 10.9928 31.4519 11.1931 31.212 11.3321L16.9061 19.6972C16.6676 19.84 16.3961 19.9175 16.1186 19.9218ZM4.85768 9.95778L16.1055 16.4992L27.2746 9.971L16.0267 3.41641L4.85768 9.95778Z"
      fill="white"
    />
    <path
      d="M15.9735 38C15.6975 37.9973 15.4266 37.9246 15.186 37.7885C14.9445 37.6481 14.7443 37.4457 14.6059 37.2019C14.4676 36.9581 14.396 36.6817 14.3985 36.401L14.5429 18.323C14.5423 18.0477 14.6136 17.7771 14.7495 17.5382C14.8853 17.2994 15.0811 17.1006 15.3172 16.9618L29.6362 8.58359C29.8767 8.44378 30.1496 8.37048 30.4272 8.3711C30.7049 8.37172 30.9775 8.44624 31.2173 8.58711C31.4572 8.72798 31.6558 8.9302 31.793 9.17326C31.9302 9.41632 32.0011 9.69158 31.9986 9.97115L31.8543 28.0491C31.8548 28.326 31.7822 28.598 31.6438 28.8372C31.5054 29.0764 31.3062 29.2742 31.0668 29.4103L16.7609 37.7885C16.5203 37.9246 16.2494 37.9973 15.9735 38ZM17.6797 19.248L17.5747 33.639L28.7175 27.1241L28.8225 12.7331L17.6797 19.248Z"
      fill="white"
    />
    <path
      d="M16.1202 18.3363L15.9759 36.4142L1.57812 28.036L1.70937 9.95801L16.1202 18.3363Z"
      fill="#FF6A3C"
    />
    <path
      d="M15.9731 38C15.6971 37.9974 15.4263 37.9246 15.1856 37.7886L0.78792 29.4103C0.543591 29.2732 0.341106 29.0715 0.202324 28.8269C0.0635417 28.5824 -0.00626458 28.3043 0.000441457 28.0228L0.131688 9.9448C0.137715 9.66777 0.214553 9.39698 0.354788 9.15856C0.495024 8.92015 0.693922 8.72214 0.932291 8.58366C1.17015 8.44692 1.43931 8.375 1.71321 8.375C1.9871 8.375 2.25627 8.44692 2.49412 8.58366L16.905 16.9619C17.1465 17.1023 17.3466 17.3047 17.485 17.5485C17.6234 17.7923 17.6949 18.0687 17.6925 18.3495L17.5481 36.4275C17.5473 36.704 17.4741 36.9755 17.3358 37.2145C17.1975 37.4534 16.9991 37.6515 16.7606 37.7886C16.5187 37.9216 16.2487 37.9941 15.9731 38ZM3.15035 27.1242L14.4244 33.6788L14.5294 19.2481L3.26848 12.6935L3.15035 27.1242Z"
      fill="white"
    />
  </svg>
)

export default Axicube
