import * as styles from './Modal.module.scss'
import cn from 'classnames'

import React, { useEffect } from 'react'

import { Title, SubTitle } from 'ui'
import Close from 'assets/icons/close.inline.svg'

import contact_settings from 'site/settings/contact_settings.json'

const Modal = ({ modalTitle, modalDescription, isOpen, onClose, children }) => {
  useEffect(() => {
    isOpen
      ? (document.querySelector('body').style.overflowY = 'hidden')
      : (document.querySelector('body').style.overflowY = 'unset')
  }, [isOpen])

  return isOpen ? (
    <div className={styles.modal}>
      <div className={styles.modal__background} onClick={onClose}></div>
      <div className={styles.modal__content}>
        <button
          type="button"
          className={styles.modal__content__close}
          onClick={onClose}>
          <Close />
        </button>
        <div className={styles.modal__content__container}>
          <div className={styles.modal__content__container__inner}>
            <div>
              <Title
                text={modalTitle || contact_settings.title || ''}
                className={
                  styles.modal__content__container__inner__titles__title
                }
              />
              {(modalDescription || contact_settings.sub_title) && (
                <SubTitle
                  text={modalDescription || contact_settings.sub_title}
                  variant="small"
                  className={cn(
                    styles.modal__content__container__inner__titles__title,
                    styles.subtitle,
                  )}
                />
              )}
            </div>
            <div className={styles.modal__content__container__inner__children}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default Modal
