import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import * as styles from './Input.module.scss'

import Cross from 'components/Icons/Cross'
import TextareaAutosize from 'react-textarea-autosize'

const Input = ({
  isTextarea = false,
  type,
  name,
  error,
  defaultValue = '',
  inputClassName,
  setFormValue,
  className,
  onChange,
  afterClearValue,
  alwaysInFocus = false,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue)
  const [isFocused, setIsFocused] = useState(alwaysInFocus)

  const onFocus = () => setIsFocused(true)
  const onBlur = () => setIsFocused(false)
  const onClearValue = () => {
    setValue('')
    afterClearValue && afterClearValue()
  }

  const Element = isTextarea ? TextareaAutosize : 'input'

  useEffect(() => setFormValue(name, value), [value])

  return (
    <>
      <div className={cn(styles.container, className)}>
        <button
          onMouseDown={onClearValue}
          type="button"
          className={cn(styles.button, { [styles.visible]: isFocused })}>
          <Cross className={styles.button__icon} />
        </button>
        <Element
          name={name}
          className={cn(styles.input, inputClassName, {
            [styles.errored]: !!error,
          })}
          {...props}
          value={value}
          onFocus={alwaysInFocus ? undefined : onFocus}
          onBlur={alwaysInFocus ? undefined : onBlur}
          onChange={({ target }) => setValue(target.value)}
        />
      </div>
      {error && <span className={styles.errorField}>{error}</span>}
    </>
  )
}

export default Input
