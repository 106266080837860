import React from 'react'
const AxicubeLight = props => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M20.0254 2.58186L5.70644 10.8898L5.5752 28.8443L19.9729 37.1653L34.2788 28.8443L34.4232 10.8898L20.0254 2.58186Z"
      fill="white"
    />
    <path
      d="M19.9731 38.7402C19.6972 38.7376 19.4263 38.6654 19.1857 38.5303L4.78792 30.2092C4.54359 30.073 4.34111 29.8727 4.20232 29.6298C4.06354 29.387 3.99374 29.1108 4.00044 28.8311L4.13169 10.8766C4.1376 10.603 4.21298 10.3354 4.35073 10.099C4.48848 9.86248 4.68408 9.66491 4.91917 9.5248L19.225 1.21691C19.4655 1.0749 19.7397 1 20.0191 1C20.2984 1 20.5726 1.0749 20.8131 1.21691L35.2108 9.5248C35.4497 9.66723 35.6477 9.86894 35.7856 10.1104C35.9236 10.3518 35.9969 10.6248 35.9983 10.9029L35.8539 28.8574C35.8545 29.1323 35.7818 29.4025 35.6435 29.6401C35.5051 29.8776 35.3059 30.0741 35.0665 30.2092L20.7606 38.5303C20.52 38.6654 20.2491 38.7376 19.9731 38.7402ZM7.15036 27.9387L19.9731 35.341L32.7172 27.9387L32.8353 11.7954L20.0256 4.39307L7.2816 11.8085L7.15036 27.9387Z"
      fill="#1B1D25"
    />
    <path
      d="M20.1167 20.7857C19.8407 20.7831 19.5698 20.7109 19.3292 20.5758L4.91834 12.2547C4.67877 12.1164 4.47985 11.9174 4.34161 11.6778C4.20337 11.4382 4.13068 11.1664 4.13086 10.8898C4.13329 10.6137 4.20708 10.3429 4.34506 10.1037C4.48304 9.86454 4.68053 9.66512 4.91834 9.52481L19.2242 1.21691C19.4647 1.0749 19.7389 1 20.0182 1C20.2975 1 20.5717 1.0749 20.8123 1.21691L35.21 9.52481C35.4499 9.6629 35.6492 9.86178 35.7877 10.1014C35.9263 10.341 35.9992 10.613 35.9992 10.8898C35.9992 11.1666 35.9263 11.4385 35.7877 11.6781C35.6492 11.9178 35.4499 12.1166 35.21 12.2547L20.9041 20.5626C20.6657 20.7045 20.3941 20.7815 20.1167 20.7857ZM8.85573 10.8898L20.1035 17.3865L31.2726 10.9029L20.0248 4.39307L8.85573 10.8898Z"
      fill="#1B1D25"
    />
    <path
      d="M19.9725 38.7402C19.6965 38.7376 19.4257 38.6654 19.185 38.5302C18.9435 38.3908 18.7433 38.1898 18.605 37.9476C18.4666 37.7055 18.395 37.431 18.3975 37.1522L18.5419 19.1976C18.5414 18.9243 18.6126 18.6555 18.7485 18.4183C18.8844 18.1811 19.0802 17.9837 19.3162 17.8458L33.6352 9.52479C33.8757 9.38595 34.1486 9.31314 34.4263 9.31376C34.7039 9.31437 34.9765 9.38838 35.2163 9.52829C35.4562 9.6682 35.6548 9.86904 35.792 10.1104C35.9292 10.3518 36.0002 10.6252 35.9977 10.9029L35.8533 28.8574C35.8538 29.1323 35.7812 29.4025 35.6428 29.64C35.5044 29.8776 35.3053 30.0741 35.0658 30.2092L20.76 38.5302C20.5193 38.6654 20.2485 38.7376 19.9725 38.7402ZM21.6787 20.1164L21.5737 34.4091L32.7165 27.9387L32.8215 13.6459L21.6787 20.1164Z"
      fill="#1B1D25"
    />
    <path
      d="M20.1173 19.2108L19.9729 37.1653L5.5752 28.8443L5.70644 10.8898L20.1173 19.2108Z"
      fill="#FF6A3C"
    />
    <path
      d="M19.9731 38.7403C19.6971 38.7376 19.4263 38.6654 19.1856 38.5303L4.78792 30.2092C4.54359 30.073 4.34111 29.8727 4.20232 29.6298C4.06354 29.387 3.99374 29.1108 4.00044 28.8312L4.13169 10.8767C4.13772 10.6015 4.21455 10.3326 4.35479 10.0958C4.49502 9.859 4.69392 9.66235 4.93229 9.52482C5.17015 9.38901 5.43931 9.31758 5.71321 9.31758C5.9871 9.31758 6.25627 9.38901 6.49412 9.52482L20.905 17.8458C21.1465 17.9853 21.3466 18.1863 21.485 18.4284C21.6234 18.6706 21.6949 18.9451 21.6925 19.2239L21.5481 37.1784C21.5473 37.4531 21.4741 37.7227 21.3358 37.9601C21.1975 38.1974 20.9991 38.3941 20.7606 38.5303C20.5187 38.6624 20.2487 38.7344 19.9731 38.7403ZM7.15035 27.9387L18.4244 34.4485L18.5294 20.1164L7.26848 13.6066L7.15035 27.9387Z"
      fill="#1B1D25"
    />
  </svg>
)

export default AxicubeLight
