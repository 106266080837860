import React, { useState, useLayoutEffect, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

export const ImageFromPath = ({ url, alt, ...props }) => {
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allImageSharp {
          nodes {
            gatsbyImageData
          }
        }
        allFile {
          edges {
            node {
              publicURL
            }
          }
        }
      }
    `,
  )
  const getImageNameFromSrc = src => {
    return src?.split('/').pop().split('.')[0]
  }

  const item = allFile.edges.find(
    img => getImageNameFromSrc(img.node.publicURL) === getImageNameFromSrc(url),
  )
  const publicURL = item?.node?.publicURL
  if (!publicURL) {
    console.log(
      '%c OOOOOOOOOOOOOOOHHHHHHHHHHHHHHHHHHHHHHHHHH',
      'background: #222; color: #bada55',
    )
    console.log(
      `%c Oh no! Image with path ${url} ${
        alt && `and alt ${alt}`
      } not loaded. File not found!`,
      'background: #222; color: #bada55',
    )
    console.log(
      '%c OOOOOOOOOOOOOOOHHHHHHHHHHHHHHHHHHHHHHHHHH',
      'background: #222; color: #bada55',
    )
  }
  // const img = allImageSharp.nodes.find(img => getImageNameFromSrc(img.gatsbyImageData.images.fallback.src) === getImageNameFromSrc(url));
  //const img = allImageSharp.nodes.find(img => getImageNameFromSrc(img.gatsbyImageData.images.fallback.src) === getImageNameFromSrc(url))?.gatsbyImageData?.images?.fallback;
  return <img src={!!publicURL ? publicURL : url} alt={alt} {...props} />
}

export function useWindowSize() {
  const [size, setSize] = useState([0, 0])

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

export const useObserver = ref => {
  const [isIntersecting, setIsIntersecting] = useState(false)
  const [isScrolledFromTop, setIsScrolledFromTop] = useState(false)

  const observerCallback = entries => {
    entries.forEach(entry => {
      setIsScrolledFromTop(entry.boundingClientRect.top > 0)
      setIsIntersecting(entry.isIntersecting)
    })
  }
  useEffect(() => {
    if (!ref?.current) return
    const observer = new IntersectionObserver(observerCallback)

    observer.observe(ref.current)
    return () => {
      try {
        observer.unobserve(ref.current)
      } catch (error) {}
    }
  }, [])

  return [isIntersecting, isScrolledFromTop]
}

export const useDelayUnmount = (isMounted, delayTime) => {
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    let timeoutId
    if (isMounted && !shouldRender) {
      setShouldRender(true)
    } else if (!isMounted && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delayTime)
    }
    return () => clearTimeout(timeoutId)
  }, [isMounted, delayTime, shouldRender])

  return shouldRender
}

const preventDefault = e => {
  e.preventDefault()
}

const preventDefaultForScrollKeys = e => {
  // left: 37, up: 38, right: 39, down: 40,
  // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
  const keys = { 37: 1, 38: 1, 39: 1, 40: 1 }
  if (keys[e.keyCode]) {
    preventDefault(e)
    return false
  }
}

// modern Chrome requires { passive: false } when adding event

const getWheelOptAndEvent = () => {
  let supportsPassive = false
  try {
    window.addEventListener(
      'test',
      null,
      Object.defineProperty({}, 'passive', {
        get: function () {
          supportsPassive = true
        },
      }),
    )
  } catch (e) {}

  const wheelOpt = supportsPassive ? { passive: false } : false
  const wheelEvent =
    'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'

  return [wheelOpt, wheelEvent]
}

// call this to Disable
export const disableScroll = () => {
  const [wheelOpt, wheelEvent] = getWheelOptAndEvent()
  window.addEventListener('DOMMouseScroll', preventDefault, false) // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt) // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt) // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false)
}

// call this to Enable
export const enableScroll = () => {
  const [wheelOpt, wheelEvent] = getWheelOptAndEvent()
  window.removeEventListener('DOMMouseScroll', preventDefault, false)
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt)
  window.removeEventListener('touchmove', preventDefault, wheelOpt)
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false)
}

export const getRandomItemFromArray = array =>
  array[Math.floor(Math.random() * array.length)]

export const isOdd = num => num % 2
