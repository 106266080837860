import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

import * as styles from './Menu.module.scss'
import cn from 'classnames'

import Modal from 'components/Modal'
import Form from 'components/FrontPage/FeedbackSection/Form/Form'

const Menu = () => {
  const [modalIsOpen, setmodalIsOpen] = useState(false)
  const handleOpenModal = () => setmodalIsOpen(true)
  const handleCloseModal = () => setmodalIsOpen(false)

  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/(services)/" } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          nodes {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    `,
  )

  return (
    <div className="row">
      <div className={cn(styles.container, 'col-6')}>
        <p className={styles.title}>Find your way</p>
        <ul className={styles.menu}>
          <li>
            <Link to="/portfolio" className={styles.link}>
              <p className={styles.par}>Portfolio</p>
            </Link>
          </li>
          <li>
            <Link to="/about" className={styles.link}>
              <p className={styles.par}>About Us</p>
            </Link>
          </li>
          <li>
            <Link to="/blog" className={styles.link}>
              <p className={styles.par}>Blog</p>
            </Link>
          </li>
          <li>
            <button
              type="button"
              className={cn(styles.par, styles.button)}
              onClick={handleOpenModal}>
              Let’s talk
            </button>
          </li>
        </ul>
      </div>
      <div className={cn(styles.container, 'col-6')}>
        <Link to="/services" className={styles.link}>
          <p className={styles.title}>Services</p>
        </Link>

        <ul className={styles.menu}>
          {allMarkdownRemark.nodes.map(({ fields, frontmatter }, index) => (
            <li key={`footes-service-item-${index}`}>
              <Link to={`/services${fields.slug}`} className={styles.link}>
                <p className={styles.par}>{frontmatter.title}</p>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <Modal isOpen={modalIsOpen} onClose={handleCloseModal}>
        <Form isModal afterSubmit={handleCloseModal}/>
      </Modal>
    </div>
  )
}

export default Menu
