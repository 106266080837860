import React, { useState, useEffect, useMemo } from 'react'
import cn from 'classnames'

import * as styles from './Header.module.scss'

import { Link } from 'gatsby'

import Modal from 'components/Modal'

import { useWindowSize } from 'utils'

import { disableScroll, enableScroll } from 'utils'

import { DefaultButton as Button } from 'ui'
import NavigationLinks from './NavigationLinks'
import NavigationLinksBurger from './NavigationLinksBurger'

import Form from 'components/FrontPage/FeedbackSection/Form/Form'
import Brand from 'components/Brand'

import { headerLinks } from './headerLinks'

const Header = ({ isTransparentOnTop }) => {
  const [isOpenBurger, setIsOpenBurger] = useState(false)
  const [modalIsOpen, setmodalIsOpen] = useState(false)
  const [scrollPos, setScrollPos] = useState(0)
  // const [bound, setBound] = useState('up')

  const [windowWidth] = useWindowSize()

  const handleToggleIsOpenBurger = () => setIsOpenBurger(open => !open)
  const handleCloseIsOpenBurger = () => setIsOpenBurger(false)

  const handleOpenModal = () => setmodalIsOpen(true)
  const handleCloseModal = () => setmodalIsOpen(false)

  const isBurger = useMemo(() => windowWidth < 768, [windowWidth])

  const scrollDetect = () => {
    if (document.body.getBoundingClientRect().top >= scrollPos) {
      // setBound('up')
      document
        .querySelector('#gatsby-plugin-page-progress')
        ?.classList.remove('active')
    } else {
      document
        .querySelector('#gatsby-plugin-page-progress')
        ?.classList.add('active')
      // setBound('down')
    }
    setScrollPos(document.body.getBoundingClientRect().top)
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollDetect)
    return () => {
      window.removeEventListener('scroll', scrollDetect)
    }
  }, [scrollPos, isOpenBurger])

  useEffect(() => {
    if (isOpenBurger) {
      disableScroll()
    } else {
      enableScroll()
    }
  }, [isOpenBurger])

  return (
    <>
      <header
        className={cn(styles.header, {
          [styles.transparent]:
            isOpenBurger || (isTransparentOnTop && scrollPos == 0),
        })}>
        <nav className={cn('container', styles.header__nav)}>
          <Brand variant="light" className={styles.header__nav__brand} />

          {!isBurger ? (
            <NavigationLinks
              links={headerLinks}
              onButtonClick={handleOpenModal}
            />
          ) : (
            <NavigationLinksBurger
              links={headerLinks}
              onButtonClick={handleOpenModal}
              isOpenBurger={isOpenBurger}
              onToggleBurger={handleToggleIsOpenBurger}
              onCloseBurger={handleCloseIsOpenBurger}
            />
          )}
          {/* <ul className="navigation-socialLinks only-mobile">
              <li>
                <a href={socialLinks.behance} target="_blank" rel="noreferrer">
                  <Behance />
                </a>
              </li>
              <li>
                <a to={socialLinks.drible} target="_blank" rel="noreferrer">
                  <Drible />
                </a>
              </li>
              <li>
                <a
                  href={socialLinks.instagram}
                  target="_blank"
                  rel="noreferrer">
                  <Inst />
                </a>
              </li>
              <li>
                <a href={socialLinks.linkedin} target="_blank" rel="noreferrer">
                  <LinkedIn />
                </a>
              </li>
            </ul> */}

          {/* <div className="col burger-col z-index">
            <button
              className={`burger btn ${isOpen ? 'active' : ''}`}
              onClick={handleToggleOpen}>
              <span className="line1"></span>
              <span className="line2"></span>
              <span className="line3"></span>
            </button>
          </div> */}
        </nav>
      </header>
      <Modal isOpen={modalIsOpen} onClose={handleCloseModal}>
        <Form isModal afterSubmit={handleCloseModal} />
      </Modal>
    </>
  )
}

export default Header
// return (
//   <>
//     <header
//       className={cn(styles.header, {
//         [styles.transparent]:
//           isOpen || (isTransparentOnTop && scrollPos == 0),
//       })}>
//       <nav className={cn('container', styles.header__nav)}>
//         <div className="row align-items-center">
//           <div className="col z-index">
//             <Brand variant="light" />
//           </div>
//           <div className={`col right navigation ${isOpen ? 'active' : ''}`}>
//             <ul className="navigation-links">
//               <li className="mobile-link only-mobile">
//                 <Link to="/">Homepage</Link>
//               </li>
//               <li>
//                 <Link to="/services">Services</Link>
//               </li>
//               <li>
//                 <Link to="/portfolio">Portfolio</Link>
//               </li>
//               <li>
//                 <Link to="/about">About Us</Link>
//               </li>
//               <li>
//                 <Link to="/blog">Blog</Link>
//               </li>
//               <li className="only-mobile">
//                 <Link to="/#hireUs" onClick={handleToggleOpen}>
//                   Hire Us
//                 </Link>
//               </li>
//               <li>
//                 <Button
//                   type="button"
//                   title="Let’s Talk"
//                   variant="secondary"
//                   onClick={handleOpenModal}
//                   style={{ fontSize: '16px', lineHeight: '24px' }}
//                 />
//               </li>
//             </ul>
//             <ul className="navigation-socialLinks only-mobile">
//               <li>
//                 <a
//                   href={socialLinks.behance}
//                   target="_blank"
//                   rel="noreferrer">
//                   <Behance />
//                 </a>
//               </li>
//               <li>
//                 <a to={socialLinks.drible} target="_blank" rel="noreferrer">
//                   <Drible />
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href={socialLinks.instagram}
//                   target="_blank"
//                   rel="noreferrer">
//                   <Inst />
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href={socialLinks.linkedin}
//                   target="_blank"
//                   rel="noreferrer">
//                   <LinkedIn />
//                 </a>
//               </li>
//             </ul>
//           </div>
//           <div className="col burger-col z-index">
//             <button
//               className={`burger btn ${isOpen ? 'active' : ''}`}
//               onClick={handleToggleOpen}>
//               <span className="line1"></span>
//               <span className="line2"></span>
//               <span className="line3"></span>
//             </button>
//           </div>
//         </div>
//       </nav>
//     </header>
//     <Modal isOpen={modalIsOpen} onClose={handleCloseModal}>
//       <Form isModal afterSubmit={handleCloseModal} />
//     </Modal>
//   </>
// )
