export const headerLinks = [
  {
    title: 'Services',
    linkTo: '/services',
  },
  {
    title: 'Portfolio',
    linkTo: '/portfolio',
  },
  {
    title: 'About Us',
    linkTo: '/about',
  },
  {
    title: 'Blog',
    linkTo: '/blog',
  },
]
