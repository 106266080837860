import React from 'react'
import cn from 'classnames'

import * as styles from './BurgerMenu.module.scss'

import { Link } from 'gatsby'
import { DefaultButton as Button } from 'ui'
import SocialLinks from './SocialLinks'

const BurgerMenu = ({ links, onClickHireUs, onButtonClick, className }) => {
  return (
    <div className={cn(styles.menu, className)}>
      <ul className={styles.list}>
        <li className={styles.list__item}>
          <Link to="/" className={styles.list__item__link}>
            Homepage
          </Link>
        </li>
        {links.map(({ title, linkTo }, i) => (
          <li
            className={styles.list__item}
            key={`NavigationLinksBurger-link-${linkTo}-${i}`}>
            <Link to={linkTo} className={styles.list__item__link}>
              {title}
            </Link>
          </li>
        ))}
        <li className={styles.list__item}>
          <Link
            to="/#hireUs"
            className={styles.list__item__link}
            onClick={onClickHireUs}>
            Hire Us
          </Link>
        </li>
        <li className={styles.list__item}>
          <Button
            type="button"
            title="Let’s Talk"
            variant="secondary"
            onClick={onButtonClick}
            style={{ fontSize: '16px', lineHeight: '24px' }}
          />
        </li>
      </ul>
      <SocialLinks />
    </div>
  )
}

export default BurgerMenu
