import React from 'react'
import * as styles from './ActionButton.module.scss'

import cn from 'classnames'

const ActionButton = ({
  title,
  titleSecond,
  variant = '',
  isMounted = 'none',
  className,
  ...props
}) => {
  const classNames = Array.isArray(variant)
    ? variant.map(v => styles[v])
    : [styles[variant]]

  const mountedClass =
    isMounted === 'none' ? undefined : isMounted ? styles.mount : styles.unmount

  return (
    <button
      className={cn(styles.button, ...classNames, mountedClass, className)}
      {...props}>
      <p>{title}</p>
      {titleSecond && <p>{titleSecond}</p>}
    </button>
  )
}

export default ActionButton
