import React from 'react'
import * as styles from './DefaultLink.module.scss'

import { Link } from 'gatsby'
import ChevronRight from 'components/Icons/ChevronRight'
import cn from 'classnames'

const DefaultLink = ({
  title,
  type,
  to,
  className,
  variant = '',
  ...props
}) => {
  // Variants: primary, secondary, ghost.
  const classNames = Array.isArray(variant)
    ? variant.map(v => styles[v])
    : [styles[variant]]

  return (
    <Link
      to={to}
      className={cn(styles.link, ...classNames, className)}
      {...props}>
      {title}
      <ChevronRight className={styles.icon} />
    </Link>
  )
}

export default DefaultLink
