import React from 'react'
import * as styles from './SubTitle.module.scss'
import cn from 'classnames'

const SubTitle = ({ text, variant, as, className }) => {
  const Component = as || 'p'

  const classNames = Array.isArray(variant)
    ? variant.map(v => styles[v])
    : [styles[variant]]
  return (
    <Component className={cn(styles.par, ...classNames, className)}>
      {text}
    </Component>
  )
}
export default SubTitle
