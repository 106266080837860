import React from 'react'

import * as styles from './SocialLinks.module.scss'

import socialLinks from 'site/settings/social_links.json'

import Inst from 'assets/icons/inst.inline.svg'
import Behance from 'assets/icons/beh.inline.svg'
import Drible from 'assets/icons/drible.inline.svg'
import LinkedIn from 'assets/icons/linked.inline.svg'

const SocialLinks = () => {
  return (
    <ul className={styles.list}>
      <li  className={styles.list__item}>
        <a href={socialLinks.behance} target="_blank" rel="noreferrer">
          <Behance />
        </a>
      </li>
      <li className={styles.list__item}>
        <a to={socialLinks.drible} target="_blank" rel="noreferrer">
          <Drible />
        </a>
      </li>
      <li className={styles.list__item}>
        <a href={socialLinks.instagram} target="_blank" rel="noreferrer">
          <Inst />
        </a>
      </li>
      <li className={styles.list__item}>
        <a href={socialLinks.linkedin} target="_blank" rel="noreferrer">
          <LinkedIn />
        </a>
      </li>
    </ul>
  )
}

export default SocialLinks
