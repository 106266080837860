// extracted by mini-css-extract-plugin
export var fadeSlide = "Modal-module--fadeSlide--gkwSg";
export var modal = "Modal-module--modal--hupZC";
export var modal__background = "Modal-module--modal__background--eAq3-";
export var modal__content = "Modal-module--modal__content--EycTl";
export var modal__content__close = "Modal-module--modal__content__close--jb-u6";
export var modal__content__container = "Modal-module--modal__content__container--mRGB1";
export var modal__content__container__inner = "Modal-module--modal__content__container__inner--vortT";
export var modal__content__container__inner__children = "Modal-module--modal__content__container__inner__children--ZtemW";
export var modal__content__container__inner__titles__title = "Modal-module--modal__content__container__inner__titles__title--8p-Zo";
export var modal__content__titles__title = "Modal-module--modal__content__titles__title--sRVGE";
export var subtitle = "Modal-module--subtitle--Uxief";