import React from 'react'
import * as styles from './Images.module.scss'

const Images = () => (
  <ul className={styles.links}>
    <li className={styles.widgetWrap}>
      <div
        className="clutch-widget"
        data-url="https://widget.clutch.co"
        data-widget-type="2"
        data-height="45"
        data-nofollow="true"
        data-expandifr="true"
        data-scale="100"
        data-clutchcompany-id="1798075"></div>
    </li>
    <li>
      <img src="/img/topRated.png" alt="Top Rated" />
    </li>
  </ul>
)

export default Images
