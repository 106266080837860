import React from 'react'
import * as styles from './Links.module.scss'
import cn from 'classnames'

import socialLinks from 'site/settings/social_links.json'

import Instagram from 'components/Icons/Instagram'
import Behance from 'components/Icons/Behance'
import LinkedIn from 'components/Icons/LinkedIn'
import Drible from 'components/Icons/Drible'

const Links = () => (
  <ul className={styles.links}>
    <li>
      <a
        href={socialLinks.behance}
        target="_blank"
        rel="noreferrer"
        title="Behance">
        <Behance className={cn(styles.icon, styles.fill)} />
      </a>
    </li>
    <li>
      <a
        href={socialLinks.drible}
        target="_blank"
        rel="noreferrer"
        title="dribbble">
        <Drible className={cn(styles.icon, styles.fill)} />
      </a>
    </li>
    <li>
      <a
        href={socialLinks.linkedin}
        target="_blank"
        rel="noreferrer"
        title="linkedin">
        <LinkedIn className={cn(styles.icon, styles.stroke)} />
      </a>
    </li>
    <li>
      <a
        href={socialLinks.instagram}
        target="_blank"
        rel="noreferrer"
        title="instagram">
        <Instagram className={cn(styles.icon, styles.fill)} />
      </a>
    </li>
  </ul>
)

export default Links
