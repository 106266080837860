import React from 'react'
import * as styles from './MainTitle.module.scss'
import cn from 'classnames'

const MainTitle = ({ text, className, as }) => {
  const Element = as || 'h1'

  return <Element className={cn(styles.title, className)}>{text}</Element>
}
export default MainTitle
