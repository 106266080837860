import * as React from 'react'
import { FeedbackMesageProvider } from 'contexts/FeedbackMesageContext'

import Header from 'components/Header'
import Footer from 'components/Footer'

import { MainTitle, SubMainTitle } from 'ui'

const Layout = ({
  title,
  subTitle,
  className,
  titleClassName,
  noHeader,
  mainTagStyles = {},
  ExtraTopComponent,
  ExtraRightComponent,
  ExtraUnderTitlesComponent,
  children,
}) => {
  return (
    <FeedbackMesageProvider>
      {!noHeader && <Header />}
      <main className={className ? className : ''} style={{ ...mainTagStyles }}>
        {ExtraTopComponent && ExtraTopComponent}
        {title && (
          <div className="container">
            <div className="row">
              <div className="col-xxl-8 col-lg-8 col-md-12">
                <MainTitle text={title} className={titleClassName} />
                {subTitle && <SubMainTitle text={subTitle} />}
                {ExtraUnderTitlesComponent && ExtraUnderTitlesComponent}
              </div>
              {ExtraRightComponent && ExtraRightComponent}
            </div>
          </div>
        )}
        {children}
      </main>
      <Footer />
    </FeedbackMesageProvider>
  )
}

export default Layout
